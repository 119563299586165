<template>
  <ion-page>
    <ion-content  :full-screens="true">

        <section id="options">
            <div class="container">
                <ion-grid>
                    <ion-row>
                        <ion-col size="12">
                            <div class="box" style="padding-bottom: 0;">
                              <div class="list-nav">
                                <ion-grid>
                                  <ion-row>
                                    <ion-col size="12" size-lg="6">
                                    <h5 style="font-weight: 400; padding: 0.5rem 0">Restaurante</h5>
                                    <h1>Nuevo Pedido</h1>

                                    </ion-col>
                                    <ion-col size="12" size-lg="6">
                                        <ion-buttons class="options-nav">
                                          <ion-button>
                                            <ion-icon color="dark" slot="icon-only" :icon="closeCircleOutline"></ion-icon>
                                          </ion-button>
                                        </ion-buttons>
                                    </ion-col>
                                  </ion-row>
                                  <ion-row class="p-sm np-x">
                                    <ion-col size="12" size-md="9" size-lg="9" size-xl="9">
                                      <span class="p-input-icon-left search" style="width: 100%;" c>
                                        <i class="pi pi-search" />
                                        <InputText placeholder="Buscar..." class="search" />
                                      </span>
                                    </ion-col>
                                    <ion-col size="12" size-md="3" size-lg="3" size-xl="3">
                                        <h5>Total: 99.99€</h5>
                                    </ion-col>
                                  </ion-row>
                                </ion-grid>
                              </div>
                            </div>
        
                            <ion-grid class="box">
                                <ion-row v-if="!selectedCategory">
                                    <ion-col size="6" size-sm="4" size-md="3" size-lg="2" size-xl="2" v-for="category in dishesCategories" :key="category.objectID">
                                        <div class="cubicle" @click="selectCategory(category)">
                                            <div class="thumbnail">
                                                <img :src="category.images[0]" :alt="category.name"/>
                                            </div>
                                        
                                            <div class="p-xs">
                                                <p class="title weight-bold">{{category.name}}</p>
                                                <p class="price">{{ (dishes.filter(x => x.category.id === category.objectID)).length }} artículos</p>
                                            </div>
                                        </div>
                                    </ion-col>
                                </ion-row>
                                <ion-row v-if="selectedCategory">
                                    <ion-col size="6" size-sm="4" size-md="3" size-lg="2" size-xl="2">
                                        <div class="cubicle" @click="selectedCategory = undefined">
                                            <div class="thumbnail">
                                                <ion-icon :icon="arrowBackOutline"></ion-icon>
                                            </div>
                                        
                                            <div class="p-xs">
                                                <p class="title weight-bold">Volver a categorías</p>
                                                <p class="price">-</p>
                                            </div>
                                        </div>
                                    </ion-col>
                                    <ion-col size="6" size-sm="4" size-md="3" size-lg="2" size-xl="2" v-for="dish in dishes.filter(x => x.category.id === selectedCategory.objectID)" :key="dish.objectID">
                                        <div class="cubicle">
                                            <div class="thumbnail">
                                                <img :src="dish.images[0]['256']" :alt="dish.name"/>
                                            </div>
                                        
                                            <div class="p-xs">
                                                <p class="title weight-bold">{{dish.name}}</p>
                                                <p class="price">{{dish.price.toFixed(2)}}€</p>
                                            </div>
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </section>

        <ion-fab vertical="bottom" horizontal="end" slot="fixed" class="p-xs" @click="scrollToTop">
          <ion-fab-button color="dark">
            <ion-icon :icon="basketOutline" color="light"></ion-icon>
          </ion-fab-button>
        </ion-fab>

      <Footer></Footer>
    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonFab, IonFabButton, IonButton, IonButtons} from '@ionic/vue';

import { basketOutline, closeCircleOutline, arrowBackOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    Footer,
    InputText,
    IonFab,
    IonFabButton,
    IonButtons, 
    IonButton,
  },
    data() {
      return {
          selectedCategory: undefined
      };
    },
    computed: {
      ...mapState('restaurant', {
        dishesCategories: state => state.dishesCategories,
        dishes: state => state.dishes,
      }),

      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('restaurant',['getDishesCategories', 'getDishes']),
      ...mapActions(['openDetails']),

      selectCategory(category){
          this.selectedCategory = category;
      }
    },
    beforeMount(){
        this.getDishesCategories();
        this.getDishes();
    },
    setup() {
      return { basketOutline, closeCircleOutline, arrowBackOutline };
    }
});
</script>

<style scoped>
    .cubicle{
        border: 1px solid var(--ion-color-light-shade);
        cursor: pointer;
    }

    .cubicle .thumbnail{
        background-color: var(--ion-color-light);
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
        position: relative; /* If you want text inside of it */
    }

    .cubicle .thumbnail img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .cubicle .thumbnail ion-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 50%;
        transform: translateX(-50%) translateY(-50%);
        object-fit: cover;
        object-position: center;
    }

    .cubicle .title{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
        min-height: 50px;
    }

    .cubicle .price{
        color: var(--ion-color-dark-tint);
    }


</style>